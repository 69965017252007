.table {
    font-size: 0.9em;
}

.pagination li button {
    font-size: 0.8em;
}

.container-form {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 100;
}

.form {
    background-color: rgba(255,255,255,0.95);
    border: 1px gray solid;
    border-radius: 0.5em;
    min-width: 50%;
    min-height: 20%;
    position: relative;    
    max-height: 90vh;
}

.form-header {
    display: flex;
    justify-content: space-between;
    background-color: #e2e2e2;
}

.form-body {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 80vh;
}

.form-notice {
    font-size: 0.8em;
}


.list1 {
    list-style: none;
    padding-left: 0;
}

.list1 li::before {
    content: '>';
    color: red;
    padding-right: 5px;
}

.list-subcampos-container {
    max-height: 150px;
    overflow-y: auto;
    border: 1px gray solid;
}

.list-subcampos {
    padding-left: 0;
    list-style: none;
}

.list-subcampos li::marker {
    font-weight: bold;
}

.active {
    font-weight: bold;
}

.fondo-pattern {
    background-color: #9d9d9d;
opacity: 1;
background-image:  linear-gradient(135deg, #7c7c7c 25%, transparent 25%), linear-gradient(225deg, #7c7c7c 25%, transparent 25%), linear-gradient(45deg, #7c7c7c 25%, transparent 25%), linear-gradient(315deg, #7c7c7c 25%, #9d9d9d 25%);
background-position:  4px 0, 4px 0, 0 0, 0 0;
background-size: 4px 4px;
background-repeat: repeat;
}

.blinking{
    animation:blinkingText 0.8s infinite;
}

@keyframes blinkingText{
    0%{     color: rgb(62, 11, 248);    }
    50%{    color: transparent; }
    100%{   color: rgb(62, 11, 248);    }
}


/* .hbotQl {
    white-space: pre-line !important;
} */